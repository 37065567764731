import isMobile from "is-mobile";
import TriviaGameFeedback from "../../Common/ApiCall/TriviaGameFeedback";
import {useEffect, useState} from "react";

type FeedbackProps = {
  gameId: string,
  pageId: string
}

const Feedback = (
  {
    gameId,
    pageId
  }: FeedbackProps
) => {
  const [star, setStar] = useState(false)

  useEffect(() => {
    setStar(true)
  }, [])

  const submitFeedback = (liked: "yes" | "no") => {
    let b = {
      page_id: pageId,
      mobile: isMobile() ? "yes" : "no",
      game_id: gameId,
      liked: liked
    }
    setStar(false)
    TriviaGameFeedback(b)
  }

  if(!star) return null

  return(
  <div
    key={"feedback"}
    style={{
      width: "80%",
      margin: "20px auto 0",
      maxWidth: "300px",
    }}
  >
    <div
      className={"card has-text-centered py-2"}
      style={{
        border: "3.69388px solid #F4F6F7",
        boxShadow: "0px 22.1633px 29.551px rgba(41, 41, 41, 0.25)",
        borderRadius: "22.1633px"
      }}
    >
      <h5 className={"mb-0 py-2 has-text-weight-semibold"}>
        Did you like this trivia?
      </h5>
      <button
        title={"Could be better"}
        className={"button px-2"}
        onClick={()=>submitFeedback("no")}
        style={{
          backgroundColor: "#F5F5F5"
        }}
      >
        😞
      </button>
      <button
        className={"button px-2 ml-3"}
        title={"Loved It"}
        onClick={()=>submitFeedback("yes")}
        style={{
          backgroundColor: "#F5F5F5"
        }}
      >
        😄
      </button>
    </div>
  </div>
)
}

export default Feedback