import communityDetails from "../../Common/Models/CommunityDetails";
import {message} from "antd";
import {useState} from "react";
import QuizFinalReward from "../../Common/Models/QuizFinalReward";
import Feedback from "./Feedback";
import {finalRedeem, INITIAL_REDIRECT_TIME} from "./ScoreCard"
import {useCountdown} from "react-countdown-circle-timer";

export type ScoreCardProps = {
  status: 0 | 1 | 2 | 3,
  brandDetails: communityDetails | null,
  score: number,
  max: number,
  reward: QuizFinalReward,
  gameId: any
}

const QUALIFYING_PERCENTAGE = 0

const ScoreCard = (
  {
    status,
    brandDetails,
    score,
    max,
    reward,
    gameId
  }: ScoreCardProps
) => {
  const [s, setShow] = useState(false)
  const [copied, setCopied] = useState(false)
  let qual = QUALIFYING_PERCENTAGE
  const {
    elapsedTime,
  } = useCountdown(
    {
      isPlaying: true,
      duration: INITIAL_REDIRECT_TIME,
      colors: '#fff',
      onComplete: () => {
        // finalRedeem(reward)
      }
    }
  )

  if(status !== 3 || !brandDetails) return null

  const copyCode = () => {
    window.navigator.clipboard.writeText(reward.code)
    // setCopied(true)
    message.success("Coupon Code Copied")
  }

  let p = score*100 / max;

  const PERC = (elapsedTime / INITIAL_REDIRECT_TIME) * 100

  if(p < qual) {
    return (
      <div className={"ib-card-parent"}>
        <div className={"card ib-community-card switched"}>
          <div className={"card-content ib-card-content has-text-centered"}>
            <h4 className={"title is-size-4 is-uppercase"}>
              results
            </h4>
            <div className={"p-6 is-size-1 has-text-weight-bold"}>
              TRY AGAIN!
            </div>
            <div className={"content has-text-centered pt-5 mb-5 "}>
              <p className={"has-text-weight-bold"}>
                Enjoyed the quiz?
              </p>
              Share it on Instagram and get 20% off on your next order!
            </div>
          </div>
        </div>
      </div>
    )
  }

  return(
    <div className={"ib-card-parent p-2 sullenbg"}>
      <div
        className={"card ib-community-card"}//Kill Switched
        style={{
          minHeight: "220px"
        }}
        onAnimationEnd={() => {
          setShow(true)
        }}
      >
        <div
          className={"card-content ib-card-content"}
        >
          {
            score === 0 ? (
              <div>
                <h3
                  className="title is-size-3-desktop has-text-weight-semibold pb-2 is-capitalized"
                  style={{
                    borderBottom: "1px solid #000000"
                  }}
                >
                  nice try
                </h3>
              </div>
            ) : (
              <div>
                <h4 className={"is-pulled-right is-size-5 is-uppercase"}>
                  <span
                    className={"py-2 px-3"}
                    style={{
                      fontFamily: 'Open Sans, sans-serif',
                      fontWeight: "700",
                      letterSpacing: "20%",
                      fontSize: "15px"
                    }}
                  >
                    result:
                  </span>
                  <span
                    className={"py-2 px-5 has-text-weight-bold"}
                    style={{
                      backgroundColor: "#F4F6F7",
                      borderRadius: "30px"
                    }}
                  >
                    {score} / {max}
                  </span>
                </h4>
                <h3
                  className="title is-size-3-desktop has-text-weight-semibold pb-2 is-capitalized"
                  style={{
                    borderBottom: "1px solid #000000"
                  }}
                >
                  Congratulations! Here's your reward
                </h3>
              </div>
            )
          }
        </div>
      </div>
      <div
        style={{
          position: "static",
          // @ts-ignore
          visibility: s ? "visible" : "hidden",
          transition: "fadeinto 2s"
        }}
      >
        <div className={"has-text-centered"}>
          <div
            className={"card teeth-down-card mx-6"}
            style={{
              position: "relative",
              top: "-120px",
              marginBottom: "-80px",
              display: "inline-block",
              width: "60%"
            }}
          >
            <div
              className={"has-text-centered card-content"}
              style={{
                position:"relative"
              }}
            >
              <div className={"is-size-2 is-size-1-desktop has-text-weight-bold"}>
                {reward.value}% OFF
              </div>
              <div
                className={"has-text-white has-text-weight-semibold has-text-centered is-uppercase py-2 mt-2"}
                style={{
                  backgroundColor: "#6C63FF",
                  border: "2px solid #6057EB",
                  boxShadow: "0px 4.23881px 4.23881px rgba(0, 0, 0, 0.25)",
                  borderRadius: "6px"
                }}
              >
                expires in {reward.expiry} days
              </div>
              <div
                className="teeth-down"
              />
              <div className={"mt-4 mb-0"}>
              <span
                className={`${copied ? "copied" : ""} ib-reward-box has-text-weight-bold is-size-4-tablet is-size-5 px-4 py-2`}
                style={{
                  background: "rgba(217, 217, 217, 0.27)",
                  borderRadius: "6px",
                  display: "block"
                }}
              >
                {reward.code}
                <i
                  className="fa fa-clipboard pl-6 is-clickable"
                  aria-hidden="true"
                  onClick={() => copyCode()}
                />
              </span>
                <Feedback
                  pageId={String(brandDetails.id)}
                  gameId={gameId}
                />
                <button
                  className={"button is-medium is-dark is-uppercase has-text-weight-bold px-6 is-rounded"}
                  style={{
                    top: "50px",
                    // background: `linear-gradient(to right, #000 0%, #000 ${PERC}%, gray ${PERC}%, gray 100%)`,
                    // backgroundColor: "#000",
                    // color: "white",
                  }}
                  onClick={() => copyCode()}
                  title={"Continue & Use Discount"}
                >
                  Continue & Use Discount
                </button>
              </div>
            </div>
          </div>
          <div className={"columns is-centered"}>
            <div className={"column is-narrow"}>
              <div className={"card mx-4"}>
                <div className={"card-content"} id={"is-paddingless"}>
                  <div className={"columns is-gapless p-3 is-mobile"}>
                    <div className={"column is-narrow"}>
                      <i
                        className="fa fa-info-circle"
                        aria-hidden="true"
                      />
                    </div>
                    <div
                      className={"column is-size-6"}
                      style={{
                        fontFamily: 'Open Sans, sans-serif',
                        fontWeight: "700",
                        letterSpacing: "20%"
                      }}
                    >
                      This code has been sent to your
                      email as well.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ScoreCard