import CookieConstants from "./CoookieConstants";
import QuizFinalReward from "../../Common/Models/QuizFinalReward";
import GamePlayerCookieState from "../../Common/Models/GamePlayerCookieState";

function windowScrollToTop() {
  window.parent.postMessage("scrollToTop", "*")
}

function redeemBarReward(finalReward: QuizFinalReward) {
  // console.log("call parent", finalReward)
  if(!finalReward || !finalReward.code) return
  let code = finalReward.code
  let exp_days = finalReward.expiry
  let exp_minutes = finalReward.expiry_minutes
  let exp_seconds = 0
  if(exp_minutes) {
    exp_seconds = exp_minutes * 60
  } else {
    exp_seconds = exp_days * 86400
  }
  windowParentSetCode(CookieConstants.BAR_REDEEM_EVENT_NAME, code, exp_seconds)
}

function setGameUser(userDetails: GamePlayerCookieState) {
  if(userDetails) {
    windowParentSetCookie(CookieConstants.GAME_USER_EVENT_NAME, userDetails)
  }
}

function moreVisits() {

}

function windowParentSetCode(code_name:string,code_value: any, expiry_seconds: any) {
  let exp = addSeconds(new Date(), expiry_seconds)
  let b = {
    name: code_name,
    code: code_value,
    expiry: exp
  }
  // console.log("mess", b)
  window.parent.postMessage(b, "*")
}

function windowParentSetCookie(eventName: string, eventDetails = {}) {
  let body = {
    name: eventName,
    data: JSON.stringify(eventDetails)
  }
  window.parent.postMessage(body, "*")
}

function checkUserMessage() {
  window.parent.postMessage(CookieConstants.GAME_USER_SEARCH_EVENT_NAME, "*")
}

function addSeconds(date: Date, seconds: number) {
  return new Date(date.getTime() + seconds * 1000);
}
function addMinutes(date: { getTime: () => number; }, minutes: number) {
  return new Date(date.getTime() + minutes*60000);
}

const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

function hexfix(str: string) {
  str = str.substring(1)
  if (str.length === 3) {
    str = str.split('').map(function (hex) {
      return hex + hex;
    }).join('');
  }
  return str
}

function getRandomNumber0toN(N: number) {
  return Math.floor(Math.random() * (N+1));
}

function getRandomAlphabet() {
  let allAlphabets = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  return allAlphabets[getRandomNumber0toN(25)]
}

function get2DArray(rows: number, cols: number, element = null) {
  return new Array(rows).fill(element).map(() => new Array(cols).fill(element));
}

const capitalize = (s: string) => s && s[0].toUpperCase() + s.slice(1)

export {
  windowScrollToTop, redeemBarReward, setGameUser,
  checkUserMessage, validateEmail, hexfix,
  capitalize, getRandomAlphabet, getRandomNumber0toN,
  get2DArray
}