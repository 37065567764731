import {useEffect, useState} from "react";
import Feedback from "./Feedback";
import {ScoreCardProps} from "./ScoreCard.desktop";
import {useCountdown} from "react-countdown-circle-timer";
import CookieConstants from "../../components/CoookieConstants";
import QuizFinalReward from "../../Common/Models/QuizFinalReward";
import {message} from "antd";

export function getRedeemLink (code: string) {
  return document.referrer+`/discount/${code}/?utm_source=influencerbit&utm_medium=website&utm_campaign=quiz`
}

export function redeemBarReward(finalReward: any) {
  if(!finalReward || !finalReward.code) return
  let code = finalReward.code
  let exp_days = finalReward.expiry
  let exp_minutes = finalReward.expiry_minutes
  let exp_seconds = 0
  if(exp_minutes) {
    exp_seconds = exp_minutes * 60
  } else {
    exp_seconds = exp_days * 86400
  }
  windowParentSetCode(CookieConstants.BAR_REDEEM_EVENT_NAME, code, exp_seconds)
}

function windowParentSetCode(code_name:string,code_value: any, expiry_seconds: any) {
  let exp = addSeconds(new Date(), expiry_seconds)
  let b = {
    name: code_name,
    code: code_value,
    expiry: exp
  }
  window.parent.postMessage(b, "*")
}

function addSeconds(date: Date, seconds: number) {
  return new Date(date.getTime() + seconds * 1000);
}

export const finalRedeem = async (reward: QuizFinalReward) => {
  const text = reward.code
  window.navigator.clipboard.writeText(text)
  await redeemBarReward(reward)
  window.parent.location.href = getRedeemLink(reward.code)
}

export const INITIAL_REDIRECT_TIME = 10

const ScoreCard = (
  {
    status,
    brandDetails,
    score,
    max,
    reward,
    gameId
  }: ScoreCardProps
) => {
  const [copied, setCopied] = useState(false)
  const [s, setShow] = useState(false)
  const {
    elapsedTime,
  } = useCountdown(
    {
      isPlaying: true,
      duration: INITIAL_REDIRECT_TIME,
      colors: '#fff',
      onComplete: () => {
        // finalRedeem(reward)
      }
    }
  )

  useEffect(() => {
  }, [status])

  if(status !== 3 || !brandDetails) return null

  const copyCode = () => {
    window.navigator.clipboard.writeText(reward.code)
    // setCopied(true)
    message.success("Coupon Code Copied")
    // finalRedeem(fina)
  }

  const PERC = (elapsedTime / INITIAL_REDIRECT_TIME) * 100

  return(
    <div className={"ib-card-parent sullenbg m-0 p-2"}>
      <div
        className={"card ib-community-card"}//Kill Switched
        style={{
          minHeight: score ? "270px" : "290px",
          backgroundColor: "black",
        }}
        onAnimationEnd={() => {
          setShow(true)
        }}
      >
        <div
          className={"card-content ib-card-content"}
        >
          {
            score === 0 ? (
              <div className={"has-text-centered"}>
                <span
                  className={"py-1 px-5 title is-size-4 has-text-weight-bold is-uppercase"}
                  style={{
                    backgroundColor: "#F4F6F7",
                    borderRadius: "30px"
                  }}
                >
                nice try
                </span>
                <h5
                  className={"has-text-white py-3 is-uppercase"}
                  style={{
                    fontFamily: "Open Sans, sans-serif"
                  }}
                >
                  thanks for playing
                </h5>
                <h3
                  className="title is-size-3-desktop has-text-weight-semibold pb-2 is-capitalized"
                  style={{
                    borderBottom: "1px solid #000000",
                    color: 'white'
                  }}
                >
                  your prize:
                </h3>
              </div>
            ) : (
              <div>
                <h4 className={"title is-size-4 is-uppercase"}>
                  <span
                    className={"py-2 is-size-6 is-pulled-left"}
                    style={{
                      fontFamily: 'Open Sans, sans-serif',
                      fontWeight: "700",
                      letterSpacing: "20%",
                      lineHeight: "30px",
                      color: "white"
                    }}
                  >
                    result:
                  </span>
                  <span>&nbsp;</span>
                    <span
                      className={"is-pulled-right py-2 px-5"}
                      style={{
                        backgroundColor: "#F4F6F7",
                        borderRadius: "30px"
                      }}
                    >
                    {score} / {max}
                  </span>
                </h4>
                <h3
                  className="title is-size-3-desktop has-text-weight-semibold py-3 is-capitalized"
                  style={{
                    borderBottom: "1px solid #000000",
                    color: 'white'
                  }}
                >
                  Congratulations!
                </h3>
              </div>
            )
          }
        </div>
      </div>
      <div
        className={"mt-2"}
        style={{
          position: "static",
          // @ts-ignore
          visibility: s ? "visible" : "hidden",
          transition: "fadeinto 2s"
        }}
      >
        <div
          className={"card teeth-down-card mx-3 "}
          style={{
            position: "relative",
            top: "-120px",
            marginBottom: "-80px"
          }}
        >
          <div
            className={"has-text-centered card-content ibcardpt"}
            style={{
              position:"relative",
              border: "1.76289px solid #F4F6F7",
              mixBlendMode: "normal",
              boxShadow: "0px 10.5773px 14.1031px rgba(41, 41, 41, 0.25)",
              borderRadius: "10.5773px"
            }}
          >
            <div className={"is-size-2 is-size-1-desktop has-text-weight-bold"}>
              {reward.value}% OFF
            </div>
            <div
              className={"has-text-white has-text-weight-semibold has-text-centered is-uppercase py-2 mt-2"}
              style={{
                backgroundColor: "#6C63FF",
                border: "2px solid #6057EB",
                boxShadow: "0px 4.23881px 4.23881px rgba(0, 0, 0, 0.25)",
                borderRadius: "6px"
              }}
            >
              expires in {reward.expiry} days
            </div>
            <div
              className="teeth-down"
            />
            <div className={"mt-4 mb-0"}>
              <span
                className={`${copied ? "copied" : ""} ib-reward-box has-text-weight-bold is-size-4-tablet is-size-5 px-4 py-2`}
                style={{
                  background: "rgba(217, 217, 217, 0.27)",
                  borderRadius: "6px",
                  display: "block"
                }}
              >
                {reward.code}
                <i
                  className="fa fa-clipboard is-clickable pl-6"
                  aria-hidden="true"
                  onClick={() => copyCode()}
                />
              </span>
              <Feedback
                pageId={String(brandDetails.id)}
                gameId={gameId}
              />
              <button
                className={"button is-dark is-uppercase has-text-weight-bold px-6 is-rounded"}
                style={{
                  top: "50px",
                  // background: `linear-gradient(to right, #000 0%, #000 ${PERC}%, gray ${PERC}%, gray 100%)`,
                  // backgroundColor: "#000",
                  // color: "white",
                }}
                onClick={() => copyCode()}
                title={"Continue & Use Discount"}
              >
                Continue & Use Discount
              </button>
            </div>
          </div>
        </div>
        <div
          className={"card mx-4"}
          style={{
            backgroundColor: "black"
          }}
        >
          <div className={"card-content"} id={"is-paddingless"}>
            <div className={"columns is-gapless p-3 is-mobile"}>
              <div className={"column is-narrow"}>
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  style={{
                    color: "white"
                  }}
                />
              </div>
              <div
                className={"column"}
                style={{
                  fontFamily: 'Open Sans, sans-serif',
                  fontWeight: "700",
                  letterSpacing: "20%",
                  color: "white"
                }}
              >
                This code has been sent to your
                email as well.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ScoreCard