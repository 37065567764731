import QuizRewardItem from "../../Common/Models/QuizRewardItem";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRibbon, faAward, faGift } from '@fortawesome/free-solid-svg-icons'
import r1 from '../../Common/Images/ribbon/ribbon1.png'
import r2 from '../../Common/Images/ribbon/ribbon2.png'
import r3 from '../../Common/Images/ribbon/ribbon3.png'
import r4 from '../../Common/Images/ribbon/ribbon4.png'
import prizeImage from '../../Common/Images/sullen/prizeImage'
import RewardItem from "./RewardItem";

type RewardsProps = {
  rewardItems: QuizRewardItem[]
}

const Rewards = (
  {
    rewardItems
  }: RewardsProps
) => {

  return(
    <div
      className={"card my-5 sullenbg3"}
      style={{
        borderRadius: "20.5251px",
        border: "3.42085px solid #6BDEF2",
        backgroundSize: "130%"
      }}
    >
      <div
        className={"card-content ibcardp has-text-centered"}
      >
        <img
          src={prizeImage}
          className={"my-3 prize-image-heading"}
          alt={"Prizes"}
        />
        <div className={"columns is-justify-content-space-around is-variable is-8-desktop is-multiline"}>
          {rewardItems.map((a) => (
            <RewardItem
              item={a}
              key={a.id}
            />
          ))}
        </div>
      </div>
    </div>
  )
}


// <FontAwesomeIcon
//   icon={faAward}
//   className={iconClassName}
//   style={iconStyles(a.rank)}
// />

export default Rewards