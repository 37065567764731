import communityDetails from "../../Common/Models/CommunityDetails";
import {useEffect, useState} from "react";
import QuizQuestionModel from "../../Common/Models/QuizQuestion";
import QuizQuestion from "./QuizQuestion";
import ProgressTimer, {useStateAndRef} from "./ProgressTimer";
import correctAud from '../../Common/Sounds/Correct-answer.mp3'
import wrongAud from '../../Common/Sounds/Wrong-answer.mp3'
import Sleep from "../../Common/Utils/Sleep";
import isMobile from "is-mobile";
import QuizProgressBar from "./QuizProgressBar";
import QuizAnswer from "../../Common/Models/QuizAnswer";
import { animateScroll as scroll} from 'react-scroll'
import {correctMusic, wrongMusic} from "./QuizHome";

type QuizProps = {
  status: 0 | 1 | 2 | 3,
  brandDetails: communityDetails | null,
  complete: Function
}

const Quiz = (
  {
    status,
    brandDetails,
    complete
  }: QuizProps
) => {
  const [score, setScore] = useState(0)
  const [maxScore, setMaxScore] = useState(0)
  const [curr, setCurr, currRef] = useStateAndRef(0)
  const [pres, setPres] = useState<QuizQuestionModel | null>(null)
  const [startTime, setStartTime] = useState(Date.now())
  const [hold, setHold, holdRef] = useStateAndRef(false)
  const [answers, setAnswers] = useState<{number: QuizAnswer }>({})

  useEffect(() => {
    setMaxScore(brandDetails?.questions.length ?? 0)
  }, [brandDetails])

  useEffect(() => {
    if(status === 1) start()
  }, [status])

  useEffect(() => {
    //console.log("Curr", curr)
  }, [curr])

  const start = () => {
    let j = 1;
    let q = brandDetails?.questions.filter(i => i.id === j)[0];
    if(!q) {
      start();return;
    }
    setPres(q)
    setCurr(j)
    scroll.scrollToTop()
    setStartTime(Date.now())
  }

  if(status !== 1 || !brandDetails || !pres) return null

  const playAudio = (correct = false) => {
    if(correct) {
      correctMusic.volume = 0.4
      correctMusic.play()
    } else {
      wrongMusic.volume = 0.4
      wrongMusic.play()
    }
    // const aud = new Audio(correct ? correctAud : wrongAud)
    // aud.volume = 0.1
    // aud.play()
  }

  const answer = (questionId: number, correct: boolean, answerObj: any) => {
    let finalScore = score + (correct ? 1 : 0);
    let finalAnswers = {
      ...answers,
      [curr]: answerObj
    }
    setAnswers(finalAnswers)
    if(correct) {
      setScore(finalScore)
      playAudio(true)
    } else {
      playAudio()
    }
    setHold(true)
    setTimeout(() => {
      //console.log("Answer", correct, hold, curr, maxScore)
      setHold(false)
      if(curr === maxScore) {
        setCurr(-1)
        complete(finalScore, finalAnswers)
      } else {
        setCurr(curr + 1)
        let q = brandDetails?.questions.filter(i => i.id === curr+1)[0];
        setPres(q ?? null)
      }
    }, 1600)
  }

  const skip = async (q: number) => {
    //console.log("Called Skip", currRef.current, q, holdRef.current)
    if(holdRef.current || currRef.current === -1 || currRef.current > q) return
    await Sleep(300)
    if(curr === -1) return
    answer(curr, false, {
      questionTitle: pres.title,
      answered: "",
      skipped: true,
      correct: false,
      questionKey: curr,
      answerKey: null
    })
  }

  let t = new Date()
  t.setSeconds(t.getSeconds() + 2)

  return(
    <div className={"sullenbg"}>
      <div
        className={"ib-card-parent"}
        key={curr}
      >
        <div
          className={"card ib-community-card sullenbg3"}
        >
          <div className={"card-content ib-card-content"}>
            <h4
              className={`title has-text-white is-size-4 is-size-6-mobile has-text-centered heading ${isMobile()?"mb-3":""}`}
            >
              Question {curr}
            </h4>
            <div className={"columns is-centered"}>
              <QuizQuestion
                question={pres}
                options={pres.options}
                answer={answer}
                hold={hold}
                key={pres.title}
              />
            </div>
            <div
              className={"has-text-centered mt-5 has-text-weight-bold has-text-white"}
            >
              Your Score (Correct Answers): {score}
            </div>
          </div>
          <ProgressTimer
            skip={skip}
            startTime={startTime}
            expiryTimestamp={t}
            hold={hold}
            curr={curr}
            key={"progress_timer"}
          />
        </div>
      </div>

      <QuizProgressBar
        max={brandDetails.questions.length}
        current={currRef.current}
        answers={answers}
      />
    </div>
  )
}

export default Quiz