import {useEffect, useState} from "react";
import {useParams, useSearchParams} from "react-router-dom";
import {Button, Form, message, Result, Spin} from "antd";
import CommunityDetails from "../../Common/Models/CommunityDetails";
import {BadNotif, SuccessNotif} from "../../Common/Utils/SendNotification";
import isMobile from "is-mobile";
import {capitalize, validateEmail} from "../BrandPage/BrandPage";
import Loader from "../../components/Loader";
import SEO from "../../components/SEO";
import something from "../../Common/Images/something.png";
import FAQs from "../BrandApplication/FAQs";
import GetTriviaDetails from "../../Common/ApiCall/GetTriviaDetails";
import Quiz from "./Quiz";
import GetTriviaPlayEligible from "../../Common/ApiCall/GetTriviaPlayEligible";
import ScoreCard from "./ScoreCard";
import PicturesGalleria from "../../components/PicturesGalleria";
import TriviaGamePlayed from "../../Common/ApiCall/TriviaGamePlayed";
import Rewards from "./Rewards";
import TriviaCommunityDetails from "../../Common/Models/TriviaCommunityDetails";
import QuizFinalReward from "../../Common/Models/QuizFinalReward";
import ScoreCardDesktop from "./ScoreCard.desktop";
import bg from '../../Common/Images/sullen/tattoo_trivia_BG.jpg'
import logo from '../../Common/Images/sullen/sullen-logo-quiz.png'
import {ReactNotifications, Store} from "react-notifications-component";
import TriviaGameFeedback from "../../Common/ApiCall/TriviaGameFeedback";
import {getPageVisits, visitPage} from "./TheCook";
import {SyncLoader} from "react-spinners";
import {windowScrollToTop} from "../../components/helperFunctions/helperFunctions";
import correctAud from "../../Common/Sounds/Correct-answer.mp3";
import wrongAud from "../../Common/Sounds/Wrong-answer.mp3";
import winAud from '../../Common/Sounds/win_audio.mp3'

/*
status
0 - form
1 - quiz
2 - retry screen
3 - thanks for playing (won)
 */

const DEFAULT_IMAGE = "https://i.ibb.co/KjfbQmt/sullen-trivia.gif"

const HERO_BODY_CLASSNAME = "hero-body"

export const correctMusic = new Audio(correctAud)
export const wrongMusic = new Audio(wrongAud)
export const winMusic = new Audio(winAud)

const QuizHome = () => {
  const [bName, setBname] = useState<string | number | null>(process.env.NODE_ENV === 'development' ? 41 : 6)
  const [it, setIt] = useState(true)
  const [load, setLoad] = useState(false)
  const p = useParams()
  const [iForm] = Form.useForm()
  const [done, setDone] = useState<0 | 1 | 2 | 3>(0)
  const [searchP] = useSearchParams()
  const [brandDetails, setBrandDetails] = useState<TriviaCommunityDetails | null>(null)
  const [score, setScore] = useState(0)
  const [gameId, setGameId] = useState<null | number>(null)
  const [finalReward, setFinalReward] = useState<QuizFinalReward | null>(null)

  useEffect(() => {
    getBrandDetails()
    seedInputsFromSearch()
  }, [bName])

  const seedInputsFromSearch = () => {
    if(searchP) {
      let email = searchP.get("email")
      let name = searchP.get("name")
      let username = searchP.get("username")
      if(email) {
        iForm.setFieldsValue({
          'ib-community-email': email
        })
      }
      if(username) {
        iForm.setFieldsValue({
          'ib-community-instagram': username
        })
      }
    }
  }

  const feedback = (liked: "yes" | "no") => {
    return
    let a = iForm.getFieldsValue()
    let b = {
      email: a["ib-community-email"],
      page_id: bName,
      username: a["ib-community-instagram"],
      mobile: isMobile() ? "yes" : "no",
      game_id: gameId,
      liked: liked
    }
    TriviaGameFeedback(b)
  }

  useEffect(() => {
    // setTimeout(() => {
    //   setDone(3)
    //   setScore(3)
    //   setFinalReward({
    //     code: "ABCDEjadbhwj",
    //     share: false,
    //     text: "Nice",
    //     value: 8,
    //     type: "percentage",
    //     expiry: 5
    //   })
    // }, 1400)
    // getFeedback()
    // iForm.setFieldsValue({
    //   "ib-community-instagram": "aman"
    // })
    // setDone(3)
    //setLoad(true)
    // setTimeout(() => {
    //   setLoad(true)
    // }, 1000)
    // setTimeout(() => {
    //   iForm.setFieldsValue({
    //     "ib-community-instagram": "aman"
    //   })
    //   setLoad(false)
    //   setDone(3)
    // }, 2000)
/*    if(brandDetails) {
      iForm.setFieldsValue({
        "ib-community-email": "test@gmail.com"
      })
      formSubmit()
    }*/
  }, [brandDetails])

  async function getBrandDetails() {
    if(bName) {
      let f = Number(searchP.get("ib_visits")) ? "no" : "yes"
      let m = isMobile() ? "yes" : "no"
      visitPage()
      const res = await GetTriviaDetails(bName, f, m)
      setIt(false)
      if(res.success) {
        let data = res.data
        setBrandDetails({...data})
        let d = document.getElementsByClassName("ib-bg")[0];
        //@ts-ignore
        d.style.backgroundColor = res.data.theme_bg
      } else {
        BadNotif(res)
      }
    }
  }

  function rgbToHsl(color: string) {
    if(!color) return;
    color = color.substring(1);
    const r = parseInt(color.substr(0, 2), 16);
    const g = parseInt(color.substr(2, 2), 16);
    const b = parseInt(color.substr(4, 2), 16);
    console.log(r, g, b)
    return 0.2126*r + 0.7152*g + 0.0722*b
  }

  async function formSubmit() {
    let a = iForm.getFieldsValue()
    //console.log(a)
    let e = true
    let b = {
      email: a["ib-community-email"],
      page_id: bName,
      username: a["ib-community-instagram"],
      eligible: e ? "yes" : "no",
      mobile: isMobile() ? "yes" : "no"
    }
    if(!validateEmail(b.email)) {
      message.warn("Invalid Email.");return;
    }
    setLoad(true)
    const res = await GetTriviaPlayEligible(b)
    setLoad(false)
    windowScrollToTop()
    if(res.success) {
      setGameId(res.data.game_id)
      setDone(1)
    } else {
      BadNotif(res)
    }
  }

  const played = async (finalScore: number, answers: any) => {
    setLoad(true)
    let a = iForm.getFieldsValue()
    let e = true
    let b = {
      email: a["ib-community-email"],
      page_id: bName,
      username: a["ib-community-instagram"],
      eligible: e ? "yes" : "no",
      mobile: isMobile() ? "yes" : "no",
      score: finalScore,
      game_id: gameId,
      answers: answers
    }
    const r = await TriviaGamePlayed(b)
    if(r.success) {
      setFinalReward(r.data)
      setDone(3)
    } else {
      BadNotif(r)
    }
    setLoad(false)
  }

  function complete(score: number, answers: any) {
    setScore(score)
    winMusic.volume = 0.3
    winMusic.play()
    played(score, answers)
  }

  function getFeedback() {
      Store.addNotification({
        title: "Liked this Quiz?",
        type: "success",
        insert: "bottom",
        dismiss: {
          duration: 70000
        },
        container: "bottom-center",
        id: "rc-feedback",
        content: () => {
          return (
            <div
              key={"feedback"}
              style={{
                width: "80%",
                margin: "0 auto",
                maxWidth: "300px"
              }}
            >
              <div
                className={"card has-text-centered"}
                style={{
                  border: "3.69388px solid #F4F6F7",
                  boxShadow: "0px 22.1633px 29.551px rgba(41, 41, 41, 0.25)",
                  borderRadius: "22.1633px"
                }}
              >
                Did you Like this Quiz?
                <br />
                <button
                  className={"button px-2 mr-3"}
                  title={"Loved It"}
                  onClick={()=>feedback("yes")}
                  style={{
                    backgroundColor: "#F5F5F5"
                  }}
                >
                  👍
                </button>
                <button
                  title={"Could be better"}
                  className={"button px-2"}
                  onClick={()=>feedback("no")}
                  style={{
                    backgroundColor: "#F5F5F5"
                  }}
                >
                  👎
                </button>
              </div>
            </div>
          )
        }
      })
  }

  function hexfix(str: string) {
    str = str.substring(1)
    if (str.length === 3) {
      str = str.split('').map(function (hex) {
        return hex + hex;
      }).join('');
    }
    return str
  }

  const getTextColor = (color: string) => {
    color = hexfix(color)
    let c = "#fff"
    //if(l && l < 20) c = "#fff"
    return c
  }

  if(it || !brandDetails) {
    if(it) {
      return (
        <section className="hero is-fullheight sullenbg theme-bg">
          <div className={`${HERO_BODY_CLASSNAME} has-text-centered`}>
            <div className="container">
              <SyncLoader
                color={"#fff"}
                margin={10}
              />
            </div>
          </div>
        </section>
      )
    } else {
      return (
        <section className="hero is-fullheight theme-bg">
          <div className={`${HERO_BODY_CLASSNAME} has-text-centered`}>
            <div className="container">
              <Result
                status="500"
                title="500"
                subTitle="Invalid URL."
              />
            </div>
          </div>
        </section>
      )
    }
  }

  if(load) {
    return(
      <div className={"hero isheightmax sullenbg theme-bg"}>
        <SEO
          title={capitalize(brandDetails?.brand_name + " | Influencerbit Community")}
          img={brandDetails.brand_logo ?? ""}
        />
        <div className={HERO_BODY_CLASSNAME}>
          <div className={"container"}>
            <div className={"columns is-multiline is-vcentered"}>
              <div className={"column"}>
                <div className={"ib-card-parent"}>
                  <div
                    className={"card sullenbg2 ib-community-card"}
                    style={{
                      minHeight: "200px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <div
                      className={"card-content ib-card-content has-text-centered"}
                    >
                      <i
                        className="fa fa-spinner fa-spin fa-2x"
                        aria-hidden="true"
                        style={{
                          color: "white"
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return(
    <div className={"hero theme-bg"}>
      <SEO
        title={capitalize(brandDetails?.brand_name + " | Influencerbit Community")}
        img={brandDetails.brand_logo ?? ""}
      />
      <div id={"is-paddingless"} className={`${HERO_BODY_CLASSNAME}`}>
        <div className={""}>
          <div
            className={`columns mb-0 is-multiline is-vcentered ${isMobile()?"m-0":""}`}
          >
            <div className={`column pb-0 ${isMobile()?"p-0":""}`}>
              <Quiz
                status={done}
                brandDetails={brandDetails}
                complete={complete}
              />
              {
                finalReward && (
                  isMobile() ? (
                    <ScoreCard
                      status={done}
                      brandDetails={brandDetails}
                      score={score}
                      reward={finalReward}
                      max={brandDetails.questions.length}
                      gameId={gameId}
                    />
                  ) : (
                    <ScoreCardDesktop
                      status={done}
                      brandDetails={brandDetails}
                      score={score}
                      reward={finalReward}
                      max={brandDetails.questions.length}
                      gameId={gameId}
                    />
                  )
                )
              }
              <div
                className={"ib-card-parent sullenbg"}
              >
                <div
                  className={"card ib-community-caard"}
                  hidden={done !== 0}
                  style={{
                    backgroundColor: 'transparent',
                    border: "#A9389D solid 4px"
                  }}
                >
                  <div
                    className={"card-content ib-card-content has-text-centered py-6"}
                    style={{
                      background: ""
                    }}
                  >
                    <div className={`columns is-vcentered ${isMobile() ? "" : "mx-6"}`}>
                      <div className={`column has-text-left-tablet is-half ${isMobile()?"":"py-6"}`}>
                        <img
                          src={logo}
                          style={{
                            minWidth: "80%"
                          }}
                        />
                      </div>
                      <div
                        className={"column is-5-desktop is-half-tablet"}
                      >
                        <Form
                          className={"has-text-left"}
                          form={iForm}
                          onFinish={() => formSubmit()}
                        >
                          <Form.Item
                            noStyle
                          >
                            <Form.Item
                              name={"ib-community-email"}
                              rules={[{
                                required: true, type: 'email'
                              }]}
                              noStyle
                            >
                            </Form.Item>
                          </Form.Item>
                          <div
                            className={"ib-form-email-comp"}
                          >
                            <i
                              className="fa fa-ig-ib fa-2x fa-envelope"
                              aria-hidden="true"
                            />
                            <input
                              name={"email"}
                              type={"email"}
                              className={"ib-input ib-instagram-email-input"}
                              placeholder={"Enter Email Here"}
                              defaultValue={iForm.getFieldValue("ib-community-email")}
                              onChange={e => iForm.setFieldsValue({"ib-community-email": e.target.value})}
                              required
                            />
                          </div>
                          <Form.Item
                            noStyle
                            hidden
                          >
                            <Form.Item
                              name={"ib-community-instagram"}
                              noStyle
                            >
                            </Form.Item>
                            <div
                              className={"ib-form-instagram-input-comp"}
                            >
                              <i
                                className="fa fa-ig-ib fa-2x fa-instagram"
                                aria-hidden="true"
                              />
                              <input
                                type="text"
                                id="ib-instagram-username-input"
                                className="ib-input ib-instagram-username-input"
                                placeholder={"Your Instagram Username (Optional)"}
                                onChange={e => iForm.setFieldsValue({"ib-community-instagram": e.target.value})}
                                defaultValue={iForm.getFieldValue("ib-community-instagram")}
                              />
                            </div>
                          </Form.Item>
                          <Form.Item
                            className={"has-text-centered"}
                            shouldUpdate
                          >
                            {() => {
                              return(
                                <button
                                  style={{
                                    backgroundColor: "#BAEA05",
                                    color: "#000"
                                  }}
                                  disabled={load}
                                  className={"is-uppercase px-6 has-text-weight-bold is-medium mt-4 button is-rounded"}
                                  onClick={() => formSubmit()}
                                >
                                  {brandDetails.button_text}
                                </button>
                              )
                            }}
                          </Form.Item>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={"sullenbg2 px-2"}>
            <Rewards
              rewardItems={brandDetails.rewards}
            />
          </div>
          {brandDetails.faqs.show && (
            <div className={"sullenbg3"}>
              <FAQs
                campaign_id={brandDetails.id}
                faqItems={brandDetails.faqs.items}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default QuizHome